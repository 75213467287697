import {
  StatusSideBarMainMenu,
  StatusSideBarMenuProps,
} from "@/models/global-models";
import { ROUTE_PATH } from "@/route-config/route-path";
import {
  COMPONENT_CLASS,
  ACCOUNT_GROUP_STEPS,
  ACCOUNT_TYPE_STEPS,
  ASSET_CATALOG_STEPS,
  BANK_PARAMS_STEPS,
  FINANCIAL_CALENDAR_STEPS,
  IC_ENTITY,
  SIDEBAR_STATUS,
  deepCopy,
  IC_FIN_ORG_STATUS_PREFIXES,
  GL_ENTITY,
  GL_STEPS,
  TC_STAGE_STATUS,
  TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS,
  CRT_ENTITY,
} from "@/utils";

//component or feature configs
const FEE_DEPOSIT: StatusSideBarMainMenu = {
  name: "Fee component (deposit)",
  status: SIDEBAR_STATUS.not_started,
  featureId: 324234,
  componentClass: COMPONENT_CLASS["fee"],
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["FEE_GENERAL_SETUP"],
      subFeatureId: 4234,
      isDisabled: false,
      stageStatus: "feeGeneralFeatureSetup",
    },
    {
      name: "Fee configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["FEE_CONFIGURATION"],
      subFeatureId: 234,
      isDisabled: true,
      stageStatus: "feeConfig",
    },
    {
      name: "Service charge details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["FEE_SERVICE_CHARGE_DETAILS"],
      subFeatureId: 123,
      isDisabled: true,
      stageStatus: "serviceChargeDetails",
    },
    {
      name: "Earning analysis",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["FEE_EARNING_ANALYSIS"],
      subFeatureId: 900,
      isDisabled: true,
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const INTEREST_DEPOSIT: StatusSideBarMainMenu = {
  name: "Interest component (deposit)",
  status: SIDEBAR_STATUS.not_started,
  featureId: 324234,
  componentClass: COMPONENT_CLASS["interest"],
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_GENERAL_FEATURE_FORM"],
      subFeatureId: 4234,
      stageStatus: "interestGeneralFeatureSetup",
    },
    {
      name: "Foundational interest details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_FOUNDATIONAL_INTEREST_DETAILS"],
      subFeatureId: 234,
      stageStatus: "foundationalInterestDetails",
    },
    {
      name: "Indexed rate - foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_INDEX_RATE_FOUND_RATE"],
      subFeatureId: 123,
      stageStatus: "indexRateFoundationalDetails",
    },
    {
      name: "Indexed rate - rate adjustments & ranges",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_INDEX_RATE_ADJUSTMENT"],
      subFeatureId: 900,
      stageStatus: "indexRateAdjustmentRanges",
    },
    {
      name: "Indexed rate - rate offset & rounding",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_INDEX_RATE_REVIEW_OFFSET"],
      subFeatureId: 900,
      stageStatus: "indexRateOffsetRounding",
    },
    {
      name: "Indexed rate - review frequency",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_INDEX_RATE_REVIEW_FREQUENCY"],
      subFeatureId: 900,
      stageStatus: "indexRateReviewFrequency",
    },
    {
      name: "Promotional Rate - foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_PROMOTIONAL_FOUND_DETAIL_FORM"],
      subFeatureId: 900,
      stageStatus: "promotionalRateFoundationDetails",
    },
    {
      name: "Promotional Rate - rate adjustments & ranges",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_PROMOTIONAL_RATE_ADJUST_RANGE"],
      subFeatureId: 900,
      stageStatus: "promoRateAdjustmentsRanges",
    },
    {
      name: "Promotional Rate - rate offset & rounding",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_PROMOTIONAL_RATE_OFFSET_TERMS"],
      subFeatureId: 900,
      stageStatus: "promoRateOffsetsRounding",
    },
    {
      name: "Posting frequency & rounding",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_POST_FREQUENCY_ROUNDING"],
      subFeatureId: 900,
      stageStatus: "promoPostingFrequencyRounding",
    },
    {
      name: "Interest accrual details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_ACCURAL_RATE"],
      subFeatureId: 900,
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const LIMIT_DEPOSIT: StatusSideBarMainMenu = {
  name: "Limit component (deposit)",
  status: SIDEBAR_STATUS.not_started,
  href: "",
  componentClass: COMPONENT_CLASS["limit"],
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["LIMIT_GENERAL_FEATURE_FORM"],
      stageStatus: "limitGeneralFeatureSetup",
    },
    {
      name: "Account balance configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["LIMIT_ACCOUNT_BALANCE_CONFIG"],
      stageStatus: "accoutnBalanceConfiguration",
    },
    {
      name: "Restriction & limitations configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["LIMIT_RESTRICTION_CONFIG"],
      stageStatus: "restrictionConfiguration",
    },
    {
      name: "Accumulated transaction limit configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["LIMIT_ACCUMULAT_TRANSCATION_CONFIG"],
      stageStatus: "accumulatedTransactionLimitConfiguration",
    },
    {
      name: "Single transaction limit configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["LIMIT_SINGLE_TRANSACTION_CONFIG"],
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const REWARDS_DEPOSIT: StatusSideBarMainMenu = {
  name: "Rewards component (deposit)",
  status: SIDEBAR_STATUS.not_started,
  href: "",
  componentClass: COMPONENT_CLASS["reward"],
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REWARD_GENERAL_FEATURE_FORM"],
      stageStatus: "rewardGeneralFeatureSetup",
    },
    {
      name: "Reward configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REWARD_CONFIGURATION_FORM"],
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const NSF_DEPOSIT: StatusSideBarMainMenu = {
  name: "NSF component (deposit)",
  componentClass: COMPONENT_CLASS["nsf"],
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["NSF_GENERAL_SETUP"],
      stageStatus: "nsfGeneralFeatureSetup",
    },
    {
      name: "Negative limit details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["NSF_NEGATIVE_LIMITS"],
      stageStatus: "negativeLimitDetails",
    },
    {
      name: "Transaction code specifications",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["NSF_TRANSACTIONAL_CODE"],
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const TRANSACTION_DEPOSIT: StatusSideBarMainMenu = {
  name: "Transaction component (deposit)",
  componentClass: COMPONENT_CLASS["transactionDeposit"],
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["TRANSACTION_GENERAL_SETUP"],
      stageStatus: "transactionGeneralFeatureSetup",
    },
    {
      name: "Transaction code specifications",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["TRANSACTION_TRANSACTIONAL_CODE"],
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const CHARGE_OF_DEPOSIT: StatusSideBarMainMenu = {
  name: "Charge-off component (deposit)",
  componentClass: COMPONENT_CLASS["chargeOff"],
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["CHARGE_OFF_GENERAL_SETUP"],
      stageStatus: "chargeOffGeneralFeatureSetup",
    },
    {
      name: "Charge-Off timing and thresholds",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["CHARGE_OFF_TIMING_AND_THRESHOLD"],
      stageStatus: "chargeOffTimingThreshold",
    },
    {
      name: "Transaction code specifications",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["CHARGE_OFF_TRANSACTIONAL_CODE"],
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const TERM_DEPOSIT: StatusSideBarMainMenu = {
  name: "Term component (deposit)",
  componentClass: COMPONENT_CLASS["term"],
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["TERM_DEPOSIT_GENERAL_SETUP"],
      stageStatus: "termGeneralFeatureSetup",
    },
    {
      name: "Maturity configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["TERM_DEPOSIT_MATURITY_CONFIGURATION"],
      stageStatus: "maturityConfiguration",
    },
    {
      name: "Rollover product and rates configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["TERM_DEPOSIT_ROLL_OVER_PRODUCT"],
      stageStatus: "rollOverProdIntrestRateConfig",
    },
    {
      name: "Grace periods and extensions",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["TERM_DEPOSIT_GRACE_PERIODS"],
      stageStatus: "gracePeriodExtensions",
    },
    {
      name: "Penalties and adjustments",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["TERM_DEPOSIT_PENALTIES_ADJUSTMENT"],
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const RULES_DEPOSIT: StatusSideBarMainMenu = {
  name: "Rule component (deposit)",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["RULES"],
  stageStatus: "rules",
  componentClass: COMPONENT_CLASS["rules"],
  showInterstitial: true,
  interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
};

const REPAY_LOAN: StatusSideBarMainMenu = {
  name: "Repayment component (loan)",
  status: SIDEBAR_STATUS.not_started,
  featureId: 324234,
  componentClass: COMPONENT_CLASS["repay"],
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REPAYMENT_GENERAL_FEATURE_FORM"],
      subFeatureId: 4234,
      stageStatus: "repayGeneralFeatureSetup",
    },
    {
      name: "Interest & principal configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REPAYMENT_PRINCIPAL_INTEREST_FORM"],
      subFeatureId: 4234,
      stageStatus: "principlaInterestConfig",
    },
    {
      name: "Payment configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REPAYMENT_PAYMENT_CONFIGURATION_FORM"],
      subFeatureId: 4234,
      stageStatus: "paymentConfig",
    },
    {
      name: "Payment application method",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REPAYMENT_PAYMENT_DIRECTION_CHARGES"],
      subFeatureId: 4234,
      stageStatus: "paymentDirectionAndCharges",
    },
    {
      name: "Pre-payment configurations",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REPAYMENT_PRE_PAYMENT_DIRECTION_CHARGES"],
      subFeatureId: 4234,
      stageStatus: "prePaymentConfigurations",
    },
    {
      name: "Due item configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REPAYMENT_PAST_DUE_CONFIGURATIONS_AND_TRACKING"],
      subFeatureId: 4234,
      stageStatus: "pastDueConfigTracking",
    },
    {
      name: "Advanced component options",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["REPAYMENT_ADVANCED_COMPONENT_OPTIONS"],
      subFeatureId: 4234,
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const COLLATERAL_LOAN: StatusSideBarMainMenu = {
  name: "Collateral component (loan)",
  status: SIDEBAR_STATUS.not_started,
  featureId: 324234,
  componentClass: COMPONENT_CLASS["collateral"],
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["COLLATERAL_GENERAL_COMPONENT_SETUP"],
      subFeatureId: 4234,
      stageStatus: "collateralGeneralFeatureSetup",
    },
    {
      name: "Security specifications",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["COLLATERAL_SECURITY_SECIFICATIONS_OPTIONS"],
      subFeatureId: 4234,
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const INTEREST_LOAN: StatusSideBarMainMenu = {
  name: "Interest component (loan)",
  status: SIDEBAR_STATUS.not_started,
  featureId: 324234,
  componentClass: COMPONENT_CLASS["interest"],
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_GENERAL_FEATURE_FORM"],
      subFeatureId: 4234,
      stageStatus: "interestGeneralFeatureSetup",
    },
    {
      name: "Foundational interest details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_FOUNDATIONAL_INTEREST_DETAILS"],
      subFeatureId: 234,
      stageStatus: "foundationalInterestDetails",
    },
    {
      name: "Indexed rate - foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_INDEX_RATE_FOUND_DETAIL"],
      subFeatureId: 123,
      stageStatus: "indexRateFoundationalDetails",
    },
    {
      name: "Indexed rate - rate adjustments & ranges",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_INDEX_RATE_ADJUSTMENT"],
      subFeatureId: 900,
      stageStatus: "indexRateAdjustmentRanges",
    },
    {
      name: "Indexed rate - rate offset & rounding",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_INDEX_RATE_REVIEW_OFFSET"],
      subFeatureId: 900,
      stageStatus: "indexRateOffsetRounding",
    },
    {
      name: "Indexed rate - review frequency",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_INDEX_RATE_REVIEW_FREQUENCY"],
      subFeatureId: 900,
      stageStatus: "indexRateReviewFrequency",
    },
    {
      name: "Introduction Rate - foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_PROMOTIONAL_FOUND_DETAIL_FORM"],
      subFeatureId: 900,
      stageStatus: "promotionalRateFoundationDetails",
    },
    {
      name: "Introduction Rate - rate adjustments & ranges",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_PROMOTIONAL_RATE_ADJUST_RANGE"],
      subFeatureId: 900,
      stageStatus: "promoRateAdjustmentsRanges",
    },
    {
      name: "Introduction Rate - rate offset & rounding",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_PROMOTIONAL_RATE_OFFSET_TERMS"],
      subFeatureId: 900,
      stageStatus: "promoRateOffsetsRounding",
    },
    {
      name: "Posting frequency & rounding",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_POST_FREQUENCY_ROUNDING"],
      subFeatureId: 900,
      stageStatus: "promoPostingFrequencyRounding",
    },
    {
      name: "Interest accrual details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["INTEREST_LOAN_ACCURAL_RATE"],
      subFeatureId: 900,
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const FEE_LOAN: StatusSideBarMainMenu = {
  name: "Fee component (loan)",
  status: SIDEBAR_STATUS.not_started,
  featureId: 324234,
  componentClass: COMPONENT_CLASS["fee"],
  subMenuList: [
    {
      name: "General component setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["FEE_GENERAL_SETUP_LOAN"],
      subFeatureId: 4234,
      stageStatus: "feeGeneralFeatureSetup",
    },
    {
      name: "Fee configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["FEE_CONFIGURATION_LOAN"],
      subFeatureId: 4234,
      stageStatus: "feeConfig",
    },
    {
      name: "Service charge details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["FEE_SERVICE_CHARGE_DETAILS_LOAN"],
      subFeatureId: 4234,
      stageStatus: "serviceChargeDetails",
    },
    {
      name: "Earning analysis",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["FEE_EARNING_ANALYSIS_LOAN"],
      subFeatureId: 4234,
      stageStatus: "completed",
      showInterstitial: true,
      interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
    },
  ],
};

const RULES_LOAN: StatusSideBarMainMenu = {
  name: "Rule component (loan)",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["RULES"],
  stageStatus: "rules",
  componentClass: COMPONENT_CLASS["rules"],
  showInterstitial: true,
  interstitialHref: ROUTE_PATH.PRODUCT_CONFIG_INTERSTITIAL_SCREEN,
};

const PRODUCT_BASICS: StatusSideBarMainMenu = {
  sectionName: "Additional product attributes",
  name: "Product basics",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["PRODUCT_ATTRIBUTE_BASIC_FORM"],
  stageStatus: "productBasics",
  componentClass: "productBasics",
};

const CADENCES: StatusSideBarMainMenu = {
  name: "Cadences",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["PRODUCT_ATTRIBUTE_CADENCES_FORM"],
  stageStatus: "cadences",
  componentClass: "cadences",
};

const ELIGIBILITY_AND_COMPONENTS_CONFIGURATION: StatusSideBarMainMenu = {
  name: "Eligibility & features configuration",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH[
    "PRODUCT_ATTRIBUTE_ELIGIBILITY_AND_COMPONENTS_CONFIGURATION"
  ],
  stageStatus: "eligibilityAndComponentsConfiguration",
  componentClass: "eligibilityAndComponentsConfiguration",
};

const ADDITIONAL_PRODUCTS_FEATURES: StatusSideBarMainMenu = {
  name: "Additional product details",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["PRODUCT_ATTRIBUTE_PRODUCT_FEATURES"],
  stageStatus: "additionalProductFeatures",
  componentClass: "additionalProductFeatures",
};

const REGULATORY_ADHERENCE_LIMITATIONS: StatusSideBarMainMenu = {
  name: "Regulatory adherence & limitations",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["PRODUCT_ATTRIBUTE_REGULATORY_ADHERENCE"],
  stageStatus: "regulatoryAdherenceLimitations",
  componentClass: "regulatoryAdherenceLimitations",
};

const LOAN_DELINQUENCY: StatusSideBarMainMenu = {
  name: "Loan delinquency",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["PRODUCT_ATTRIBUTE_LOAN_DELINQUENCY"],
  stageStatus: "loanDelinquency",
  componentClass: "loanDelinquency",
};

export const DEPOSIT_COMPONENT_CLASS_FOR_SIDEBAR: Record<string, StatusSideBarMainMenu> = {
  componentFee: deepCopy(FEE_DEPOSIT),
  componentInt: deepCopy(INTEREST_DEPOSIT),
  componentLimit: deepCopy(LIMIT_DEPOSIT),
  componentReward: deepCopy(REWARDS_DEPOSIT),
  componentNsf: deepCopy(NSF_DEPOSIT),
  componentRepay: deepCopy({
    ...REPAY_LOAN,
    name: "Repayment component (deposit)",
  }),
  componentCollateral: deepCopy({
    ...COLLATERAL_LOAN,
    name: "Collateral component (deposit)",
  }),
  componentTd: deepCopy(TERM_DEPOSIT),
  componentDepChrgOff: deepCopy(CHARGE_OF_DEPOSIT),
  componentTrnOpt: deepCopy(TRANSACTION_DEPOSIT),
  componentRules: deepCopy(RULES_DEPOSIT),
};

export const LOAN_COMPONENT_CLASS_FOR_SIDEBAR: Record<string, StatusSideBarMainMenu> = {
  componentFee: deepCopy(FEE_LOAN),
  componentInt: deepCopy(INTEREST_LOAN),
  componentLimit: deepCopy({
    ...LIMIT_DEPOSIT,
    name: "Limit component (loan)",
  }),
  componentReward: deepCopy({
    ...REWARDS_DEPOSIT,
    name: "Rewards component (loan)",
  }),
  componentNsf: deepCopy({ ...NSF_DEPOSIT, name: "NSF Component (loan)" }),
  componentRepay: deepCopy(REPAY_LOAN),
  componentCollateral: deepCopy(COLLATERAL_LOAN),
  componentTd: deepCopy({ ...TERM_DEPOSIT, name: "Term Component (loan)" }),
  componentDepChrgOff: deepCopy({
    ...CHARGE_OF_DEPOSIT,
    name: "Charge-off Component (loan)",
  }),
  componentTrnOpt: deepCopy({
    ...TRANSACTION_DEPOSIT,
    name: "Transaction Component (loan)",
  }),
  componentRules: deepCopy({
    ...RULES_LOAN,
    name: "Rule component (loan)",
  }),
};

export const DEFAULT_ADDITIONAL_PRODUCT_ATTRIBUTES: StatusSideBarMainMenu[] = [
  PRODUCT_BASICS,
  CADENCES,
  ELIGIBILITY_AND_COMPONENTS_CONFIGURATION,
  ADDITIONAL_PRODUCTS_FEATURES,
  REGULATORY_ADHERENCE_LIMITATIONS,
  LOAN_DELINQUENCY,
];

// Institution config
const ACCOUNT_GROUP = {
  id: IC_ENTITY.account_group,
  sectionName: "Institutional Configuration",
  sectionNameTooltipDesc: "Institutional Configuration",
  name: "Account group",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "Account group foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_GROUP_FOUNDATIONAL_DETAILS"]}`,
      stageStatus: ACCOUNT_GROUP_STEPS.acc_grp_foundation_details,
    },
    {
      name: "Numbering & identification",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_GROUP_NUMBERING_IDENTIFICATION"]}`,
      stageStatus: ACCOUNT_GROUP_STEPS.acc_grp_number_identification,
    },
    {
      name: "Composite & field configuration",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_GROUP_COMPOSITE_FIELD_CONFIGURATION"]}`,
      stageStatus: ACCOUNT_GROUP_STEPS.acc_grp_composite_field,
    },
    {
      name: "Advanced configurations",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_GROUP_ADVANCED_CONFIGURATION"]}`,
      stageStatus: ACCOUNT_GROUP_STEPS.acc_grp_advanced_config,
    },
  ],
};

const ACCOUNT_GROUP_SIDEBAR = {
  id: IC_ENTITY.account_group,
  sectionName: "Details",
  sectionNameTooltipDesc: "Institutional Configuration",
  name: "Account group",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "Account group foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_GROUP_FOUNDATIONAL_DETAILS"]}`,
      stageStatus: ACCOUNT_GROUP_STEPS.acc_grp_foundation_details,
    },
    {
      name: "Numbering & identification",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_GROUP_NUMBERING_IDENTIFICATION"]}`,
      stageStatus: ACCOUNT_GROUP_STEPS.acc_grp_number_identification,
    },
    {
      name: "Composite & field configuration",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_GROUP_COMPOSITE_FIELD_CONFIGURATION"]}`,
      stageStatus: ACCOUNT_GROUP_STEPS.acc_grp_composite_field,
    },
    {
      name: "Advanced configurations",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_GROUP_ADVANCED_CONFIGURATION"]}`,
      stageStatus: ACCOUNT_GROUP_STEPS.acc_grp_advanced_config,
    },
  ],
};

const ASSET_CATALOG = {
  id: IC_ENTITY.asset_catalog,
  name: "Asset catalog",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "Asset catalog foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ASSET_CATALOG_FOUNDATIONAL_DETAILS"]}`,
      stageStatus: ASSET_CATALOG_STEPS.asset_cat_foundation_detail,
    },
    {
      name: "Asset origin & classification",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ASSET_ORIGIN_AND_CLASSIFICATION"]}`,
      stageStatus: ASSET_CATALOG_STEPS.asset_cat_origin_classification,
    },
    {
      name: "Financial specifications",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ASSET_FINANCIAL_SPECIFICATION"]}`,
      stageStatus: ASSET_CATALOG_STEPS.asset_cat_fin_spec,
    },
    {
      name: "General Ledger details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ASSET_GENERAL_LEDGER_DETAILS"]}`,
      stageStatus: ASSET_CATALOG_STEPS.asset_cat_gen_ledger,
    },
  ],
};

const ASSET_CATALOG_SIDEBAR = {
  id: IC_ENTITY.asset_catalog,
  sectionName: "Details",
  sectionNameTooltipDesc: "Institutional Configuration",
  name: "Asset catalog",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "Asset catalog foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ASSET_CATALOG_FOUNDATIONAL_DETAILS"]}`,
      stageStatus: ASSET_CATALOG_STEPS.asset_cat_foundation_detail,
    },
    {
      name: "Asset origin & classification",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ASSET_ORIGIN_AND_CLASSIFICATION"]}`,
      stageStatus: ASSET_CATALOG_STEPS.asset_cat_origin_classification,
    },
    {
      name: "Financial specifications",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ASSET_FINANCIAL_SPECIFICATION"]}`,
      stageStatus: ASSET_CATALOG_STEPS.asset_cat_fin_spec,
    },
    {
      name: "General Ledger details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ASSET_GENERAL_LEDGER_DETAILS"]}`,
      stageStatus: ASSET_CATALOG_STEPS.asset_cat_gen_ledger,
    },
  ],
};
const ACCOUNT_TYPE = {
  id: IC_ENTITY.account_type,
  name: "Account types",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "Account type foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_TYPES_FOUNDATIONAL_DETAILS"]}`,
      stageStatus: ACCOUNT_TYPE_STEPS.acc_type_foundation_detail,
    },
    {
      name: "Account type relationship foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_TYPES_RELATIONSHIP_FOUNDATIONAL_DETAILS"]}`,
      stageStatus: ACCOUNT_TYPE_STEPS.acc_type_reln_foundation_detail,
    },
    {
      name: "Account type relationship party specifications",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_TYPES_RELATIONSHIP_PARTY_SPECIFICATIONS"]}`,
      stageStatus: ACCOUNT_TYPE_STEPS.acc_type_reln_party_spec,
    },
    {
      name: "Account type relationship additional selections",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_TYPES_RELATIONSHIP_ADDITIONAL_SELECTIONS"]}`,
      stageStatus: ACCOUNT_TYPE_STEPS.acc_type_reln_add_selections,
    },
    {
      name: "Add limit component",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.IC_ACCOUNT_TYPES_ADD_LIMIT,
      stageStatus: ACCOUNT_TYPE_STEPS.acct_type_limits,
    },
    {
      name: "Account type relationship Summary",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.IC_ACCOUNT_TYPES_RELATIONSHIP_SUMMARY,
      stageStatus: ACCOUNT_TYPE_STEPS.acct_type_summary,
    },
  ],
};
const ACCOUNT_TYPE_SIDEBAR = {
  id: IC_ENTITY.account_type,
  sectionName: "Details",
  sectionNameTooltipDesc: "Institutional Configuration",
  name: "Account types",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "Account type foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_TYPES_FOUNDATIONAL_DETAILS"]}`,
      stageStatus: ACCOUNT_TYPE_STEPS.acc_type_foundation_detail,
    },
    {
      name: "Account type relationship foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_TYPES_RELATIONSHIP_FOUNDATIONAL_DETAILS"]}`,
      stageStatus: ACCOUNT_TYPE_STEPS.acc_type_reln_foundation_detail,
    },
    {
      name: "Account type relationship party specifications",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_TYPES_RELATIONSHIP_PARTY_SPECIFICATIONS"]}`,
      stageStatus: ACCOUNT_TYPE_STEPS.acc_type_reln_party_spec,
    },
    {
      name: "Account type relationship additional selections",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_ACCOUNT_TYPES_RELATIONSHIP_ADDITIONAL_SELECTIONS"]}`,
      stageStatus: ACCOUNT_TYPE_STEPS.acc_type_reln_add_selections,
    },
    {
      name: "Add limit component",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.IC_ACCOUNT_TYPES_ADD_LIMIT,
      stageStatus: ACCOUNT_TYPE_STEPS.acct_type_limits,
    },
    {
      name: "Account type relationship Summary",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.IC_ACCOUNT_TYPES_RELATIONSHIP_SUMMARY,
      stageStatus: ACCOUNT_TYPE_STEPS.acct_type_summary,
    },
  ],
};
const FINANCIAL_CALENDAR = {
  id: IC_ENTITY.financial_calendar,
  name: "Financial calendars",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "Financial calendar foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_FINANCIAL_CALENDER_FOUNDATIONAL_DETAILS"]}`,
      stageStatus: FINANCIAL_CALENDAR_STEPS.fin_cal_foundation_details,
    },
    {
      name: "Business day configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["IC_FINANCIAL_CALENDER_BUSINESS_DAY_CONFIG"],
      stageStatus: FINANCIAL_CALENDAR_STEPS.fin_cal_business_day_config,
    },
    {
      name: "Holiday configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["IC_FINANCIAL_CALENDER_HOLIDAY_CONFIGURATION"],
      stageStatus: FINANCIAL_CALENDAR_STEPS.fin_cal_holiday_config,
    },
  ],
};
const FINANCIAL_CALENDAR_SIDEBAR = {
  id: IC_ENTITY.financial_calendar,
  sectionName: "Details",
  sectionNameTooltipDesc: "Institutional Configuration",
  name: "Financial calendars",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "Financial calendar foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_FINANCIAL_CALENDER_FOUNDATIONAL_DETAILS"]}`,
      stageStatus: FINANCIAL_CALENDAR_STEPS.fin_cal_foundation_details,
    },
    {
      name: "Business day configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["IC_FINANCIAL_CALENDER_BUSINESS_DAY_CONFIG"],
      stageStatus: FINANCIAL_CALENDAR_STEPS.fin_cal_business_day_config,
    },
    {
      name: "Holiday configuration",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["IC_FINANCIAL_CALENDER_HOLIDAY_CONFIGURATION"],
      stageStatus: FINANCIAL_CALENDAR_STEPS.fin_cal_holiday_config,
    },
  ],
};
const FINANCIAL_ORGANIZATION = {
  id: IC_ENTITY.financial_organization,
  name: "Financial institution setup",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "General party parameters",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.GENERAL_PARTY_PARAMETER,
      stageStatus: IC_FIN_ORG_STATUS_PREFIXES.gen_party,
    },
    {
      name: "General organization party parameters",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.IC_GENERAL_ORGANIZATION_PARTY,
      stageStatus: IC_FIN_ORG_STATUS_PREFIXES.gen_org_party,
    },
    {
      name: "Financial institution-specific parameters",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM,
      stageStatus: IC_FIN_ORG_STATUS_PREFIXES.fin_org_spec,
    },
  ],
};
const FINANCIAL_ORGANIZATION_SIDEBAR = {
  sectionName: "Details",
  sectionNameTooltipDesc: "Institutional Configuration",
  id: IC_ENTITY.financial_organization,
  name: "Financial institution setup",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "General party parameters",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.GENERAL_PARTY_PARAMETER,
      stageStatus: IC_FIN_ORG_STATUS_PREFIXES.gen_party,
    },
    {
      name: "General organization party parameters",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.IC_GENERAL_ORGANIZATION_PARTY,
      stageStatus: IC_FIN_ORG_STATUS_PREFIXES.gen_org_party,
    },
    {
      name: "Financial institution-specific parameters",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.IC_FIN_ORG_SPEC_PARAM,
      stageStatus: IC_FIN_ORG_STATUS_PREFIXES.fin_org_spec,
    },
  ],
};
const BANK_PARAMS = {
  id: IC_ENTITY.bank_parameters,
  name: "Financial institution (bank) parameters",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "Foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_FOUNDATION_DETAILS"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_foundational_details,
    },
    {
      name: "Rates, currencies, & identifiers",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_RATES_CURRENCIES_IDENTIFIERS"]}`,
      stageStatus:
        BANK_PARAMS_STEPS.bank_params_rates_currencies_and_identifiers,
    },
    {
      name: "Cutoffs, limits & thresholds",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_CUTOFF_LIMITS_THRESHOLD"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_cutoffs_limits_thresholds,
    },
    {
      name: "Additional specifications",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_ADDITIONAL_SPECIFICATION"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_additional_specifications,
    },
    {
      name: "Base savings rates",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_BASE_SAVING_RATE"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_base_savings_rates,
    },
    {
      name: "Loan penalty rate",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_LOAN_PENALTY_RATE"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_loan_penalty_rate,
    },
    {
      name: "US financial institution (bank) parameters & information",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_US_FINANCIAL_INST_PARAMS_INFO"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_us_bank_params_info,
    },
    {
      name: "[US] FDIC370 related fields",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_FDIC_370_RELATED_FIELD"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_fdic_370_related_fields,
    },
    {
      name: "[US] SBA lending party email",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_SBA_LENDING_PARTY_EMAIL"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_sba_lending_party_email,
    },
    {
      name: "[US] SBA lending party phone",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_SBA_LENDING_PARTY_PHONE"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_sba_lending_party_phone,
    },
    {
      name: "[US] SCRA properties",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_SCRA_PROPERTIES"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_scra_properties,
    },
    {
      name: "General Ledger extract file formats",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_GL_EXTRACT_FILE_FORMATE"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_gl_extract_file_formats,
    },
    {
      name: "Tax party email contact",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_TAX_PARTY_EMAIL_CONTACT"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_tax_party_email_contact,
    },
    {
      name: "Tax party phone contact",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_TAX_LENDING_PARTY_PHONE"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_tax_party_phone_contact,
    },
    {
      name: "Priority posting",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_PRIORITY_POSTING"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_priority_posting,
    },
  ],
};
const BANK_PARAMS_SIDEBAR = {
  id: IC_ENTITY.bank_parameters,
  sectionName: "Details",
  sectionNameTooltipDesc: "Institutional Configuration",
  name: "Financial institution (bank) parameters",
  status: SIDEBAR_STATUS.not_started,
  subMenuList: [
    {
      name: "Foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_FOUNDATION_DETAILS"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_foundational_details,
    },
    {
      name: "Rates, currencies, & identifiers",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_RATES_CURRENCIES_IDENTIFIERS"]}`,
      stageStatus:
        BANK_PARAMS_STEPS.bank_params_rates_currencies_and_identifiers,
    },
    {
      name: "Cutoffs, limits & thresholds",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_CUTOFF_LIMITS_THRESHOLD"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_cutoffs_limits_thresholds,
    },
    {
      name: "Additional specifications",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_ADDITIONAL_SPECIFICATION"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_additional_specifications,
    },
    {
      name: "Base savings rates",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_BASE_SAVING_RATE"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_base_savings_rates,
    },
    {
      name: "Loan penalty rate",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_LOAN_PENALTY_RATE"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_loan_penalty_rate,
    },
    {
      name: "US financial institution (bank) parameters & information",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_US_FINANCIAL_INST_PARAMS_INFO"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_us_bank_params_info,
    },
    {
      name: "[US] FDIC370 related fields",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_FDIC_370_RELATED_FIELD"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_fdic_370_related_fields,
    },
    {
      name: "[US] SBA lending party email",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_SBA_LENDING_PARTY_EMAIL"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_sba_lending_party_email,
    },
    {
      name: "[US] SBA lending party phone",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_SBA_LENDING_PARTY_PHONE"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_sba_lending_party_phone,
    },
    {
      name: "[US] SCRA properties",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_SCRA_PROPERTIES"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_scra_properties,
    },
    {
      name: "General Ledger extract file formats",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_GL_EXTRACT_FILE_FORMATE"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_gl_extract_file_formats,
    },
    {
      name: "Tax party email contact",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_TAX_PARTY_EMAIL_CONTACT"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_tax_party_email_contact,
    },
    {
      name: "Tax party phone contact",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_TAX_LENDING_PARTY_PHONE"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_tax_party_phone_contact,
    },
    {
      name: "Priority posting",
      status: SIDEBAR_STATUS.not_started,
      href: `${ROUTE_PATH["IC_BANK_PARAMS_PRIORITY_POSTING"]}`,
      stageStatus: BANK_PARAMS_STEPS.bank_params_priority_posting,
    },
  ],
};
export const INSTITUTIONAL_CONFIGURATION_SIDE_BAR_PROPS: StatusSideBarMenuProps =
  {
    mainMenuList: [
      ACCOUNT_GROUP,
      ASSET_CATALOG,
      ACCOUNT_TYPE,
      FINANCIAL_CALENDAR,
      FINANCIAL_ORGANIZATION,
      BANK_PARAMS,
    ],
  };

export const IC_NAVBAR_MAP = {
  [IC_ENTITY.account_group]: ACCOUNT_GROUP_SIDEBAR,
  [IC_ENTITY.asset_catalog]: ASSET_CATALOG_SIDEBAR,
  [IC_ENTITY.account_type]: ACCOUNT_TYPE_SIDEBAR,
  [IC_ENTITY.financial_calendar]: FINANCIAL_CALENDAR_SIDEBAR,
  [IC_ENTITY.financial_organization]: FINANCIAL_ORGANIZATION_SIDEBAR,
  [IC_ENTITY.bank_parameters]: BANK_PARAMS_SIDEBAR,
};

const GL_ACCOUNTING_SEGMENTS = {
  sectionName: "General Ledger",
  sectionNameTooltipDesc: "General Ledger",
  name: "Accounting Segments",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["GL_ACCOUNTING_SEGMENTS"],
  stageStatus: GL_STEPS.accounting_segments,
};

const GL_ACCOUNTS = {
  name: "Accounts",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["GL_ACCOUNTS"],
  stageStatus: GL_STEPS.accounts,
};

const GL_SYSTEM_ACCOUNTS = {
  name: "System Accounts",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["GL_SYSTEM_ACCOUNTS"],
  stageStatus: GL_STEPS.system_accounts,
};

const GL_SETS = {
  name: "Sets",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["GL_SETS"],
  stageStatus: GL_STEPS.sets,
};

export const GENERAL_LEDGER_SIDE_BAR_PROPS: StatusSideBarMenuProps = {
  mainMenuList: [
    GL_ACCOUNTING_SEGMENTS,
    GL_ACCOUNTS,
    GL_SYSTEM_ACCOUNTS,
    GL_SETS,
  ],
};

export const CRT_SIDE_BAR_PROPS: StatusSideBarMenuProps = {
  mainMenuList: [
    {
      id: CRT_ENTITY.general_setup,
      sectionName: "Customer relationship types",
      sectionNameTooltipDesc: "Customer relationship types",
      name: "General setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["CRT_GENERAL_SETUP"],
      stageStatus: CRT_ENTITY.general_setup,
    },
    {
      id: CRT_ENTITY.party_relationships,
      name: "Party relationships",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH["CRT_PARTY_RELATIONSHIPS"],
      stageStatus: CRT_ENTITY.party_relationships,
    },
  ],
};

const GL_ACCOUNTING_SEGMENTS_SIDEBAR = {
  sectionName: "Details",
  sectionNameTooltipDesc: "General Ledger",
  name: "Accounting Segments",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["GL_ACCOUNTING_SEGMENTS"],
  stageStatus: GL_STEPS.accounting_segments,
};

const GL_ACCOUNTS_SIDEBAR = {
  sectionName: "Details",
  sectionNameTooltipDesc: "General Ledger",
  name: "Accounts",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["GL_ACCOUNTS"],
  stageStatus: GL_STEPS.accounts,
};

const GL_SYSTEM_ACCOUNTS_SIDEBAR = {
  sectionName: "Details",
  sectionNameTooltipDesc: "General Ledger",
  name: "System Accounts",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["GL_SYSTEM_ACCOUNTS"],
  stageStatus: GL_STEPS.system_accounts,
};

const GL_SETS_SIDEBAR = {
  sectionName: "Details",
  sectionNameTooltipDesc: "General Ledger",
  name: "Sets",
  status: SIDEBAR_STATUS.not_started,
  href: ROUTE_PATH["GL_SETS"],
  stageStatus: GL_STEPS.sets,
};

export const GL_NAVBAR_MAP = {
  [GL_ENTITY.accounting_segments]: GL_ACCOUNTING_SEGMENTS_SIDEBAR,
  [GL_ENTITY.accounts]: GL_ACCOUNTS_SIDEBAR,
  [GL_ENTITY.system_accounts]: GL_SYSTEM_ACCOUNTS_SIDEBAR,
  [GL_ENTITY.sets]: GL_SETS_SIDEBAR,
};
export const TRANS_CODE_SIDE_BAR_MENU: StatusSideBarMenuProps = {
  mainMenuList: [
    {
      sectionName: "Transaction Code",
      sectionNameTooltipDesc: "Transaction Code",
      name: "Foundational details",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.TC_FOUNDATIONAL_DETAILS,
      stageStatus: TC_STAGE_STATUS.foundational_details,
      id: TC_STAGE_STATUS.foundational_details,
    },
    {
      name: "Transaction tag setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.TC_TAG_SETUP,
      stageStatus: TC_STAGE_STATUS.tag_setup,
      id: TC_STAGE_STATUS.tag_setup,
    },
    {
      name: "Transaction entry setup",
      status: SIDEBAR_STATUS.not_started,
      href: ROUTE_PATH.TC_ENTRY_SETUP,
      stageStatus: TC_STAGE_STATUS.entry_setup,
      id: TC_STAGE_STATUS.entry_setup,
      subMenuList: [
        {
          name: "Transaction entry foundational details",
          status: SIDEBAR_STATUS.not_started,
          href: `${ROUTE_PATH["TC_TRANSACTION_ENTRY_FOUNDATIONAL_DETAILS"]}`,
          stageStatus: TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_foundational,
        },
        {
          name: "Transaction entry advanced setup",
          status: SIDEBAR_STATUS.not_started,
          href: `${ROUTE_PATH["TC_TRANSACTION_ENTRY_ADVANCED_SETUP"]}`,
          stageStatus: TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_advanced,
        },
        {
          name: "Statistical group expression entries",
          status: SIDEBAR_STATUS.not_started,
          href: `${ROUTE_PATH["TC_TRANSACTION_ENTRY_STATISTIC_GROUP"]}`,
          stageStatus:
            TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_statistical_group,
        },
        {
          name: "Accounting segments",
          status: SIDEBAR_STATUS.not_started,
          href: `${ROUTE_PATH["TC_TRANSACTION_ENTRY_ACCOUNTING_SEGMENTS"]}`,
          stageStatus:
            TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_account_segments,
        },
        {
          name: "Templates",
          status: SIDEBAR_STATUS.not_started,
          href: `${ROUTE_PATH["TC_TRANSACTION_ENTRY_TEMPLATES"]}`,
          stageStatus: TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_template,
        },
        {
          name: "Hold specifications",
          status: SIDEBAR_STATUS.not_started,
          href: `${ROUTE_PATH["TC_TRANSACTION_ENTRY_HOLD_SPECIFICATIONS"]}`,
          stageStatus:
            TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_hold_specification,
        },
        {
          name: "Field specifications (immutable vs. required)",
          status: SIDEBAR_STATUS.not_started,
          href: `${ROUTE_PATH["TC_TRANSACTION_ENTRY_FIELD_SPECIFICATIONS"]}`,
          stageStatus:
            TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_field_specification,
        },
        {
          name: "Transaction entry summary",
          status: SIDEBAR_STATUS.not_started,
          href: `${ROUTE_PATH["TC_TRANSACTION_ENTRY_SUMMARY"]}`,
          stageStatus: TC_ENTRY_SETUP_SUB_STEPS_STAGE_STATUS.entry_summary,
        },
      ],
    },
  ],
};
